<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Contacts Birthday</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <label class="switch_option capsule_btn">
                        <h5>Celebrate Birthday?</h5>
                        <input type="checkbox" id="birthday" v-model="birthday" :true-value="1" :false-value="0" hidden>
                        <div><span></span></div>
                    </label>
                    <template v-if="selectedContacts.length">
                        <h3 class="sub_header mb-1">Add/Edit Birthday</h3>
                        <p class="sub_para">These contacts will not receive birthday messages until you add a date of birth.(Click a name to edit or add the birthday)</p>
                        <ul class="birthday_list">
                            <li v-for="(contact, c) in selectedContacts" :key="c">
                                <img :src="contact.photo" alt="">{{ contact.name }}
                                <button class="edit_btn" @click="handleEditBirthday(contact)">Add/Edit</button>
                            </li>
                        </ul>
                    </template>
                    <div class="action_wpr mt-5">
                        <button :disabled="bulkActionLoader" class="btn cancel_btn" type="button" @click.self="closeModal()">Cancel</button>
                        <button :disabled="bulkActionLoader" class="btn save_btn" type="button" @click="updateBirthdayStatus()"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Updating' : 'Update' }}</button>
                    </div>
                    <div v-if="editBirthday" class="details_wpr">
                        <div class="cardItem_details">
                            <button class="close_btn" @click="editBirthday = false"><i class="fas fa-chevron-down"></i></button>
                            <h2>Edit Birthday</h2>
                            <Form @submit="handleUpdateBirthday" v-slot="{ errors }">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Birthday</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.birthdate }">
                                            <Field autocomplete="off" name="birthdate" v-model="form.birthdate" rules="required">
                                                <datepicker v-model="form.birthdate" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                            </Field>
                                        </div>
                                    </div>
                                    <ErrorMessage name="birthdate" class="text-danger" />
                                </div>
                                <div class="action_wpr mt-5">
                                    <button :disabled="birthdayLoader" type="button" class="btn cancel_btn" @click="editBirthday = false">Cancel</button>
                                    <button :disabled="birthdayLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="birthdayLoader"></i> {{ birthdayLoader ? 'Saving' : 'Save' }}</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapMutations, mapActions } from 'vuex'

    export default {
        name: 'CelebrateBirthday',

        data () {
            return {
                editBirthday: false,
                birthdayLoader: false,
                birthday: 1,
                form: {
                    contact_id: 0,
                    birthdate: '',
                },
                selectedContacts: [],
                allContacts: [],
            }
        },

        props: {
            modelValue: Boolean,
            contacts: Array,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
        },

        watch: {
            modelValue(val) {
                const vm = this;

                if (val) {
                    vm.allContacts      = JSON.parse(JSON.stringify(vm.contacts));
                    vm.selectedContacts = [];

                    if (vm.contactsList.data) {
                        vm.contactsList.data.forEach((contact) => {
                            if (vm.contacts.includes(contact.id) && !contact.birthdate) {
                                vm.selectedContacts.push(contact);
                            }
                        });
                    }

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            contactsList: state => state.contactModule.contacts,
            bulkActionLoader: state => state.contactModule.bulkActionLoader,
        }),

        methods: {
            ...mapActions({
                enableDisableDeliveryStatus: 'contactModule/enableDisableDeliveryStatus',
                updateBirthday: 'contactModule/updateBirthday',
            }),

            ...mapMutations({
                setBulkActionWatcher: 'contactModule/SET_BULK_ACTION_WATCHER',
                setBulkActionLoader: 'contactModule/SET_BULK_ACTION_LOADER_STATUS',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleEditBirthday (contact) {
                const vm = this;

                vm.form.birthdate   = contact.birthdate;
                vm.form.contact_id  = contact.contact_id;
                vm.editBirthday = true;
            },

            handleUpdateBirthday (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.birthdayLoader = true;

                vm.updateBirthday(vm.form).then((result) => {
                    if (result) {
                        const index = vm.selectedContacts.findIndex(({id}) => id == vm.form.contact_id);
                        vm.selectedContacts.splice(index , 1);

                        vm.editBirthday = false;
                        vm.form         = { contact_id: 0, birthdate: '' };
                    }

                    vm.birthdayLoader = false;
                });
            },

            updateBirthdayStatus () {
                const vm = this;

                const params = { status: vm.birthday, contact_id: vm.allContacts.join(','), type: 'birthday' };

                vm.setBulkActionLoader(true);
                vm.enableDisableDeliveryStatus(params).then((result) => {
                    if (result) {
                        vm.editBirthday = false;
                        vm.form = { contact_id: 0, birthdate: '' };
                        vm.closeModal();
                        vm.setBulkActionWatcher();
                    }

                    vm.setBulkActionLoader(false);
                });
            },
        },
    }
</script>
